<template>
    <div class="campus_schedule">
        <div>
            <div class="campus_schedule_search">
                <van-cell-group>
                    <van-cell @click="handleClick('studioShowPicker',1)" title="上课校区" is-link :value="studio.label" />
                    <van-cell @click="handleClick('teacherShowPicker')" title="上课老师" is-link :value="teacher.filter_name" />
                    <van-cell @click="handleClick('showCalendar')" title="上课时间" is-link :value="`${dateValue.length === 0 ? '' : `${dayjs(dateValue[0]).format('MM/DD')} - ${dayjs(dateValue[1]).format('MM/DD')}`}`" />
                    <van-cell title="时间范围">
                        <van-checkbox-group checked-color="#00cca2" @change="handleCheckbox" v-model="checkboxGroup" direction="horizontal">
                            <van-checkbox name="forenoon" shape="square" icon-size="18">上午</van-checkbox>
                            <van-checkbox name="afternoon" shape="square" icon-size="18">下午</van-checkbox>
                            <van-checkbox name="evening" shape="square" icon-size="18">晚上</van-checkbox>
                        </van-checkbox-group>
                    </van-cell>
                </van-cell-group>
            </div>
            <div v-for="(item,index) of list" :key="index" class="campus_schedule_item">
                <van-cell-group>
                    <van-cell @click="toPath(it)" v-for="(it,ind) in item" :label="`${it.course_name}`" :key="ind" :value="it.teacher">
                        <template #title>
                            <span>{{`${dayjs(it.class_date).format('MM/DD【dddd】')} ${it.class_time}`}}</span>
                        </template>
                        <template #label>
                            <van-tag plain type="success">{{`${it.reserve_count}`}}人</van-tag>
                            <img v-if="it.is_summary" class="campus_schedule_item-img" src="@/assets/beike-active.png" alt="">
                            <span class="campus-schedule-custom-title van-ellipsis">{{`${it.class_name}`}}</span>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
        </div>
        <van-calendar v-model="showCalendar" color="#00cca2" :default-date="defaultDate" :min-date="minDate" :max-date="maxDate" type="range" @confirm="onConfirmCalendar" />
        <van-popup v-model="studioShowPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="studioColumns"
                value-key="label"
                @cancel="onStudioPickerClose"
                @confirm="onStudioPickerConfirm"
            />
        </van-popup>
        <van-popup v-model="teacherShowPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="teachers"
                value-key="filter_name"
                @cancel="onTeacherPickerClose"
                @confirm="onteacherPickerConfirm"
            />
        </van-popup>
        <div v-if="list.length === 0 && !loading" class="no-data">
            <img :src="no_order" class="no-data-image">
            <div class="no-data-title">暂无排课</div>
        </div>
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import 'dayjs/locale/zh-cn'
    import no_order from '@/assets/no_order.png'
    import Loadings from '@/components/loading'
    import { Cell, CellGroup, DatetimePicker, Picker, Calendar, Checkbox, CheckboxGroup, Field, Tag } from 'vant'
    export default {
        name:"campusScheduleIndex",
        data() {
            return {
                no_order,
                loading:false,
                showCalendar:false,
                teacherShowPicker:false,
                studioShowPicker:false,
                studioColumns:[],
                studio: {},
                dateValue:[dayjs().format('YYYY-MM-DD'),dayjs().add(7,'day').format('YYYY-MM-DD')],
                defaultDate:[dayjs().$d, dayjs().add(7,'day').$d],
                list:[],
                studio_id:'',
                teacher:{},
                teachers:[],
                minDate: dayjs().add(-3,'month').$d,
                maxDate: dayjs().add(5,'year').$d,
                checkboxGroup:['forenoon','afternoon','evening'],
                forenoon:1,
                afternoon:1,
                evening:1,
            }
        },
        components: {
            [Tag.name]:Tag,
            [Field.name]:Field,
            [Cell.name]:Cell,
            loadings:Loadings,
            [Picker.name]:Picker,
            [Checkbox.name]:Checkbox,
            [CheckboxGroup.name]:CheckboxGroup,
            [Calendar.name]:Calendar,
            [CellGroup.name]:CellGroup,
            [DatetimePicker.name]:DatetimePicker,
        },
        async created () {
            dayjs.locale('zh-cn')
            await this.getList()
            await this.getStudio()
        },
        methods: {
            dayjs,
            async getList(){
                this.loading = true
                let obj = {studio_id:this.studio_id,forenoon:this.forenoon,afternoon:this.afternoon,evening:this.evening}
                if(this.teacher.teacher_id){
                    obj.teacher_id = this.teacher.teacher_id
                }
                if(this.dateValue.length > 0){
                    obj.start_date = this.dateValue[0]
                    obj.end_date = this.dateValue[1]
                }
                await this.$api.campus_schedule(obj)
                .then(res=>{
                    this.loading = false
                    this.list = res.data.schdule_list
                    this.studio_id = res.data.studio_id
                })
            },
            async getStudio(){
                await this.$api.filter_studios()
                .then(res => {
                    this.studioColumns = []
                    res.data.forEach(item=>{
                        this.studioColumns.push({ label: item.studio_name, value: item.studio_id })
                        if(this.studio_id == item.studio_id){
                            this.studio = { label: item.studio_name, value: item.studio_id }
                            this.getTeacher()
                        }
                    })
                })
            },
            getTeacher(){
                this.$api.filter_courseTeacher({studio_id:this.studio_id})
                .then(res=>{
                    this.teachers = res.data
                })
            },
            handleClick(name,num){
                this[name] = true
            },
            handleCheckbox(e){
                this.forenoon = e.indexOf('forenoon')!=-1?1:0
                this.afternoon = e.indexOf('afternoon')!=-1?1:0
                this.evening = e.indexOf('evening')!=-1?1:0
                this.getList()
            },
            onStudioPickerClose(){
                this.studioShowPicker = false
            },
            onStudioPickerConfirm(value){
                this.studio = value
                this.studio_id = value.value
                this.teacher = {}
                this.onStudioPickerClose()
                this.getTeacher()
                this.getList()
            },
            onConfirmCalendar(date){
                this.dateValue = [dayjs(date[0]).format('YYYY-MM-DD'),dayjs(date[1]).format('YYYY-MM-DD')]
                this.showCalendar = false
                this.getList()
            },
            onTeacherPickerClose(){
                this.teacherShowPicker = false
            },
            onteacherPickerConfirm(value){
                if(value){
                    this.teacher = value
                    this.onTeacherPickerClose()
                    this.getList()
                }
            },
            toPath(item){
                if(!item.is_summary) return false
                this.$router.push({path:'/campus/schedule/summary',query:{course_class_id:item.course_class_id}})
            }
        },
        beforeRouteLeave(to, from, next) {
            if (to.path === '/campus/schedule/summary') { // 去往详情页的时候需要缓存组件，其他情况下不需要缓存
                this.$store.commit('app/updateCatchComponents', ['campusScheduleIndex'])
            } else {
                this.$store.commit('app/updateCatchComponents', [])
            }
            next()
        },
    }
</script>

<style lang="less">
    .campus-schedule-custom-title {
        width: 200px;
        margin-left: 10px;
        vertical-align: middle;
    }
    .campus_schedule{
        .campus_schedule_search{
            .van-cell__value{
                flex: 2;
            }
            .van-checkbox--horizontal{
                margin-right: 0;
                margin-left: 12px;
            }
            .van-checkbox-group--horizontal{
                justify-content: flex-end;
            }
        }
        .van-cell-group{
            width: 95%;
            margin: 0 auto;
            border-radius: 10px;
            overflow: hidden;
            margin-top: 10px;
        }
        .campus_schedule_item{
            .van-cell__title{
                flex: 3;
            }
            .van-cell__label{
                display: flex;
                align-items: center;
            }
            &-img{
                width: 20px;
                position: absolute;
                right: 15px;
            }
        }
    }
    .no-data{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        box-sizing: border-box;
        width: 95%;
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 10px;
        min-height: 220px;
        text-align: center;
        &-image{
        width: 96px;
        height: 96px;
        background: #fff;
        margin-bottom: 10px;
        }

    }
</style>